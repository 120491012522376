;

(function($) {
    // --------------------------------------------------------------
    // jQuery Plugin
    // Muestra y oculta el boton de subir al inicio de la página
    // Depende de https://www.npmjs.com/package/jquery.easing
    // --------------------------------------------------------------

    $.scrollToTop = function(element, options) {
        //Valores por defecto del plugin
        var defaults = {
            classOn: 'footer-scroll-top-on', //Clase para el boton
            scrollIn: 100, //A partir de cuanto scroll aparece
            scrollDuration: 400, //Duración de la animación en milisegundos
            scrollAnimation: 'easeInOutQuint' //Tipo de animacion depende de jquery.easing
        }

        var plugin = this;
        plugin.settings = {}

        var $element = $(element),
            element = element;

        plugin.init = function() {
            plugin.settings = $.extend({}, defaults, options);

            $('.btn-subir').click(function(e) {
                e.preventDefault();
                plugin.scrollTop(); 
            });

            $(window).scroll(function() {
                if ($(window).scrollTop() >= plugin.settings.scrollIn) {
                    if (!$element.hasClass(plugin.settings.classOn)) {
                        $element.addClass(plugin.settings.classOn);
                    }
                } else if ($element.hasClass(plugin.settings.classOn)) {
                    $element.removeClass(plugin.settings.classOn);
                }
            });
        }

        plugin.scrollTop = function() {
            $('body, html').animate({
                scrollTop: 0
            }, plugin.settings.scrollDuration, plugin.settings.scrollAnimation);
        }

        plugin.init();
    }

    $.fn.scrollToTop = function(options) {
        return this.each(function() {
            if (undefined == $(this).data('scrollToTop')) {
                var plugin = new $.scrollToTop(this, options);
                $(this).data('scrollToTop', plugin);
            }
        });
    }
})(jQuery);
