;

(function($) {
    var that,
        settings;

    $.fn.veissModal = function(options, element) {
        settings = $.extend({
            id: '',
            class: '',
            icon: '',
            buttons: [],
        }, options);

        element = $(element || this.defaultElement || this)[0];
        this.element = $(element);
        that = this;

        // Contenido de la modal.
        setClasses();
        setHeader();
        setBody();
        setFooter();

        // Al cerrar el modal, hacer scroll a la parte superior del contenido.
        this.on('hide.bs.modal', function() {
            $(element).find('.modal-body').scrollTop(0);
        });

        this.modal('show');
    };

    /**
     * Limpiar clases del contenido.
     * Añadir las clases que se incluyen en la configuración.
     */
    function setClasses() {
        var contentClass = 'modal-content' + ' ' + settings.class;
        that.find('.modal-content').attr('class', contentClass);
    }

    /**
     * Cabecera de la modal.
     * Puede ser HTML.
     */
    function setHeader() {
        var header = that.find('.modal-header'),
            headerTxt = $('#' + settings.id + ' div.header');

        header.empty();

        if (0 == headerTxt.length) {
            header.hide();
            return;
        }

        header.show();
        header.append(headerTxt.html());
    }

    /**
     * Contenido de la modal.
     * Puede ser un icono o HTML.
     */
    function setBody() {
        var icon = settings.icon,
            body = that.find('.modal-body'),
            bodyTxt = $('#' + settings.id + ' div.body');

        body.empty();

        if (0 == bodyTxt.length && '' == icon) {
            body.hide();
            return;
        }

        body.show();

        if ('' != icon) {
            var img = '<div><img src="' + icon + '"/></div>';
            body.append(img);
        }

        if (0 < bodyTxt.length) {
            body.append(bodyTxt.html());
        }
    }

    /**
     * Pie de la modal.
     * Puede ser HTML o botones.
     */
    function setFooter() {
        var buttons = settings.buttons,
            footer = that.find('.modal-footer'),
            footerTxt = $('#' + settings.id + ' div.footer');

        footer.empty();

        if (0 == footerTxt.length && 0 == buttons.length) {
            footer.hide();
            return;
        }

        footer.show();

        if (0 < footerTxt.length) {
            footer.append(footerTxt.html());
        }

        // Botones del modal.
        $.each(buttons, function(name, props) {
            var buttonDiv = $('#' + settings.id + ' div.' + props.id);

            if (0 == buttonDiv.length) {
                return; // Equivalente a continue en PHP.
            }

            props.text = buttonDiv.html();

            var click, buttonOptions;
            props = $.isFunction(props) ?
                { click: props, text: name } :
                props;
            props = $.extend({ type: 'button' }, props);

            click = props.click;
            if (undefined != click) {
                props.click = function() {
                    click.apply(that.element[0], arguments);
                };
            }

            buttonOptions = {
                icons: props.icons,
                text: props.showText
            };

            if (!props.noClose) {
                props['data-dismiss'] = 'modal';
            }

            delete props.icons;
            delete props.noClose;
            delete props.showText;

            $('<button></button>', props)
                .addClass('btn')
                .button(buttonOptions)
                .appendTo(footer);
        });
    }
} (jQuery));
