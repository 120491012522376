;
(function($) {
    // --------------------------------------------------------------
    // jQuery Plugin
    // Muestra y oculta el menu lateral en móviles
    // --------------------------------------------------------------

    $.menuMobile = function(element, options) {
        //Valores por defecto del plugin
        var defaults = {
            classOn: 'header-hamburger-on', //Clase para el boton disparador
            classOnBody: 'body-menu-mobile-on', //Clase para el body
            classOnMenu: 'header-col-menu-mobile-on', //Clase para el menu
            classSubMenus: 'header-submenu', //Clase para lso submenus
            menuMobileWidth: 80 //Tanto por ciento de ancho del menu en mobile
        }

        var plugin = this;
        plugin.settings = {}

        var $element = $(element),
            element = element,
            $body,
            $menu,
            $submenus,
            device_width,
            menu_width,
            media_querie,
            media_querie_ini;

        plugin.init = function() {
            plugin.settings = $.extend({}, defaults, options);
            $header = $('header');
            $body = $('.main-content');
            $menu = $('[data-front="menu-mobile"]');
            $submenus = $('[data-front="submenu-trigger"]');
            media_querie_ini =  $('[data-front="jquery-media-queries"]').data('jqueryMediaQueries').getMediaQuerie();

            plugin.checkMenu();

            //Evento hamburguesa
            $element.click(function(e) {
                e.preventDefault();
                $element.toggleClass(plugin.settings.classOn);
                $menu.toggleClass(plugin.settings.classOnMenu);
                if (!$body.hasClass(plugin.settings.classOnBody)) {
                    //$body.css('left',menu_width);
                    $body.addClass(plugin.settings.classOnBody);
                    $menu.css('width', menu_width);
                    //$menu.css('left','0');
                    /*if($header.hasClass('affix')){
                        $header.css('left',menu_width);
                    }*/
                } else {
                    //$body.css('left','0');
                    $body.removeClass(plugin.settings.classOnBody);
                    $menu.css('width', menu_width);
                    //$menu.css('left',menu_width*-1);
                    //$header.css('left','0');
                }
            });

            $(window).resize(function() {
                plugin.checkMenu();
            });
        }

        plugin.checkMenu = function() {
            device_width = $(window).width();
            menu_width = (device_width * plugin.settings.menuMobileWidth) / 100;

            //Coge media querie actual --> ver en _part_jquery_media_queries.js
            media_querie = $('[data-front="jquery-media-queries"]').data('jqueryMediaQueries').getMediaQuerie();

            if (media_querie != media_querie_ini ) {
                if ('md' == media_querie || 'lg' == media_querie) { 
                    //Quita las clases del menú móvil
                    $element.removeClass(plugin.settings.classOn);
                    $body.removeClass(plugin.settings.classOnBody);
                    //$body.css('left','0');
                    $menu.css('width', '');
                    //$menu.css('left','0');
                    //$header.css('left','0');
                    $menu.removeClass(plugin.settings.classOnMenu);
                    //Quita los eventos del menú móvil
                    $submenus.unbind('click').parent().find('.' + plugin.settings.classSubMenus).show();
                } else {
                    $menu.css('width', menu_width);
                    //$menu.css('left', menu_width*-1);
                    //$body.css('left','0');
                    //Oculta los submenus en movil
                    $submenus.parent().find('.' + plugin.settings.classSubMenus).hide();
                    //Añade los eventos del menú móvil
                    $submenus.unbind('click').bind('click', function() {
                        $(this).parent().find('.' + plugin.settings.classSubMenus).slideToggle('slow');
                    });
                }
            }

            media_querie_ini = media_querie;
        }

        plugin.init();
    }

    $.fn.menuMobile = function(options) {
        return this.each(function() {
            if (undefined == $(this).data('menuMobile')) {
                var plugin = new $.menuMobile(this, options);
                $(this).data('menuMobile', plugin);
            }
        });
    }
})(jQuery);
