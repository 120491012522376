(function ($) {

    $(document).ready(function () {
        
        //-----------------------------------------------------------------------
        // Device detector
        //-----------------------------------------------------------------------
        var device_detector = new deviceDetect();

        //-----------------------------------------------------------------------
        // Google conversion formulario de contacto
        //-----------------------------------------------------------------------
        $("#contact").submit(function( event ) {

            var callback = function () {
                return
            };
            
            gtag('event', 'conversion', {
                'send_to': 'AW-764826147/mbDDCP2izZQBEKOk2ewC',
                'event_callback': callback
            });
            
        });

        gtag('config', 'AW-764826147/24P1CNnSwJQBEKOk2ewC', {
            'phone_conversion_number': '+34943820071'
        });

        //-----------------------------------------------------------------------
        // Google conversion teléfono
        //-----------------------------------------------------------------------
        $(".goo-phone-mob").click(function( event ) {

            var callback = function () {
                return
            };
            
            gtag('event', 'conversion', {
                'send_to': 'AW-764826147/s0VcCMvS05QBEKOk2ewC',
                'event_callback': callback
            });
            
        });

        //-----------------------------------------------------------------------
        // Prefetching
        //-----------------------------------------------------------------------
        var pre_urls = [];
        $(".prefetch-menu").each(function(){
            var url =  $(this).attr('href');
            pre_urls.push(url);
        });
        $(".prefetch").each(function(){
            var url =  $(this).find('a').attr('href');
            pre_urls.push(url);
        });
        quicklink({
            urls: pre_urls
        });

        //-----------------------------------------------------------------------
        // Menu
        //-----------------------------------------------------------------------

        //Hambuguer móvil
        $('#mobile-toggle, #desktop-toggle').click(function(e){
            e.preventDefault();
            $('#main-menu').toggle();
        });

        //Menu submenu display/hide
        $('.has-children').click(function(e){
            e.preventDefault(); 
            $(this).parent().find('.header-submenu').slideToggle('fast');
        });

        //-----------------------------------------------------------------------
        // Slider Home --> https://owlcarousel2.github.io/OwlCarousel2/
        //-----------------------------------------------------------------------
        $('.home-carousel').owlCarousel({
            items: 1,
            nav: false,
            touchDrag: true,
            mouseDrag: true,
            lazyLoad: true,
            lazyLoadEager: 3,
            loop: true,
            autoHeight: false,
            autoplay: true,
            autoplayTimeout: 6000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            margin: 0,
        });

        //-----------------------------------------------------------------------
        // Cookies
        //-----------------------------------------------------------------------
        $(".footer").cookiesAdvise({
            adviseLocation: "footer", //Donde pone el aviso de cookies
            language: 'es', //Idioma para las cadenas
            locale_url: '/user/themes/luisurbina/js/locale/'
        });
        

        //-----------------------------------------------------------------------
        // Scroll top footer
        //-----------------------------------------------------------------------
        //$(".footer-scroll-top").scrollToTop();
        /*
        function scrollTo(id) {
            var $scroll_target = $(id);
            var $scroll_offset = $scroll_target.offset();
            var scroll_to = $scroll_offset.top;

            $("html,body").animate(
                {
                    scrollTop: scroll_to
                },
                1500
            );
        }
        */
       $(window).scroll(function(){
            if ($(this).scrollTop() < 200) {
                $('.scroll-top') .fadeOut();
            } else {
                $('.scroll-top') .fadeIn();
            }
        });
        $('.scroll-top').on('click', function(){
            $('html, body').animate({scrollTop:0}, 'fast');
            return false;
        });

        //-----------------------------------------------------------------------
        // Galerias
        // Depende de Photoswipe
        // El plugin NO pedende de jQuery
        //-----------------------------------------------------------------------

        var galleries = [];
        var pswpElement = document.querySelectorAll('.pswp')[0];
            
        if ($('[data-front="gallery"]').length) {

            $('[data-front="gallery"]').each(function(){

                var gallery_slides = [];

                $(this).children('li').each(function () {

                    $this = $(this);
        
                    var item = {
                        src: $this.find('a').attr('href'),
                        w: $this.find('a').attr('data-width'),
                        h: $this.find('a').attr('data-height'), 
                        name: $this.find('a').attr('data-name')
                    }
        
                    gallery_slides.push(item);
        
                });

                galleries.push(gallery_slides);

            });

            var gallery;
            var gallery_auto;

            $('body').on('click', '[data-front="gallery-trigger"]', function (e) {

                e.preventDefault();

                clearInterval(gallery_auto);

                var gallery_index = $(this).attr('data-index');

                var img_name = $(this).attr('data-name');

                var gallery_to_search = galleries[gallery_index];

                var li_index = 0;

                for (var i=0; i < gallery_to_search.length; i++) {
                    if (gallery_to_search[i].name === img_name) {
                        li_index = i;
                    }
                }

                var options = {
                    index: li_index,
                    shareButtons: [
                        { id: 'facebook', label: 'Comparte en Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
                        { id: 'twitter', label: 'Comparte en Twitter', url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}' },
                        { id: 'pinterest', label: 'Pin it', url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}' },
                        { id: 'download', label: 'Descargar Imagen', url: '{{raw_image_url}}', download: true }
                    ],
                };

                gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleries[gallery_index], options);

                gallery.init();

                // Autoplay de la galeria
                gallery_auto = setInterval( function() {
                    gallery.next();
                }, 3000);

                gallery.listen('close', function() {
                    clearInterval(gallery_auto);
                });

                $('.pswp__button--arrow--left').on('click',function(){
                    clearInterval(gallery_auto);
                });

                $('.pswp__button--arrow--right').on('click',function(){
                    clearInterval(gallery_auto);
                });

                $('.pswp__button--zoom').on('click',function(){
                    clearInterval(gallery_auto);
                });

            });

        }
        //-----------------------------------------------------------------------
        // Menu Burguer
        //-----------------------------------------------------------------------
        /*
        var body_scroll;
        $(".header-hamburguer").click(function (e) {
            e.preventDefault();
            $(this)
                .find("a")
                .toggleClass("header-hamburguer__link-active");
            $(".row-header-bottom").toggleClass("menu_mobile_visible");
            if ($("body").hasClass("no-scroll")) {
                $("body").removeClass("no-scroll");
                $("body,html").scrollTop(body_scroll);
                window.scrollTo(0, body_scroll);
            } else {
                body_scroll = window.pageYOffset; //scrollTop no funciona en iOS
                $("body").addClass("no-scroll");
            }
        });
        */

        //-----------------------------------------------------------------------
        // Menu & Submenu iPad
        //-----------------------------------------------------------------------
        /*
        $('.menu-item-has-children').hover(
            function () {
                $(this).addClass('hover-touch');
            },
            function () {
                $(this).removeClass('hover-touch');
            }
        );

        var is_iPad = navigator.userAgent.match(/iPad/i) != null;

        if (is_iPad && device_detector.device == 'desktop') {

            $('.menu-item-has-children > a').on('touchstart', function (e) {
                e.preventDefault();
                var link = $(this).parent();
                if (link.hasClass('hover-touch')) {
                    link.removeClass('hover-touch');
                } else {
                    link.addClass('hover-touch');
                    $('.menu-item-has-children > a').not(this).parent().removeClass('hover-touch');
                } 
            });

        }
        */

        //-----------------------------------------------------------------------
        // Política privacidad formularios
        //-----------------------------------------------------------------------
        var $modal = $('#veissModal'),
            politicaInput = '.form-contact-legal input',
            politicaSpan = '.form-contact-legal label',
            privacidad = $(politicaInput).prop('checked'),
            politicaNInput = '.newsletter-footer .form-newsletter-legal input',
            politicaNSpan = '.newsletter-footer .form-nesletter-legal label',
            privacidadN = $(politicaNInput).prop('checked'),
            politicaHInput = '.newsletter-header .form-newsletter-legal input',
            politicaHSpan = '.newsletter-header .form-nesletter-legal label',
            privacidadH = $(politicaHInput).prop('checked');

        /**
         * 1. La política no está aceptada: mostrar modal.
         * 2. La política ya está aceptada: desmarcar.
         */
        $(document).on('click', politicaInput, function (e) {
            if (!privacidad) {
                e.preventDefault();
                modalPrivacidad();

                return;
            }

            toggleChecks(false);
        });

        $(document).on('click', politicaNInput, function (e) {
            if (!privacidadN) {
                e.preventDefault();
                modalPrivacidadN();

                return;
            }

            toggleChecksN(false);
        });

        $(document).on('click', politicaHInput, function (e) {
            if (!privacidadN) {
                e.preventDefault();
                modalPrivacidadH();

                return;
            }

            toggleChecksH(false);
        });

        /**
         * Los clicks en el label de la política de privacidad abren el modal.
         */
        $(document).on('click', politicaSpan, function (e) {
            modalPrivacidad();
        });

        $(document).on('click', politicaNSpan, function (e) {
            modalPrivacidadN();
        });

        $(document).on('click', politicaHSpan, function (e) {
            modalPrivacidadH();
        });

        /**
         * Acciones a realizar sí se reinicia el formulario.
         */
        $(politicaInput).closest('form').on('reset', function (event) {
            setTimeout(function () {
                privacidad = $(politicaInput).prop('checked');
            }, 1);
        });
        $(politicaNInput).closest('form').on('reset', function (event) {
            setTimeout(function () {
                privacidadN = $(politicaNInput).prop('checked');
            }, 1);
        });
        $(politicaHInput).closest('form').on('reset', function (event) {
            setTimeout(function () {
                privacidadH = $(politicaHInput).prop('checked');
            }, 1);
        });

        /**
         * Mostrar modal para la privacidad.
         */
        function modalPrivacidad() {
            $modal.veissModal({
                id: 'pol',
                buttons: [{
                    id: 'polnook',
                    class: 'btn btn-default modal-clear',
                    click: function () { toggleChecks(false); }
                }, {
                    id: 'polok',
                    class: 'btn btn-primary btn-serserve-on',
                    click: function () { toggleChecks(true); }
                }]
            });
        }

        function modalPrivacidadN() {
            $modal.veissModal({
                id: 'pol',
                buttons: [{
                    id: 'polnook',
                    class: 'btn btn-default modal-clear',
                    click: function () { toggleChecksN(false); }
                }, {
                    id: 'polok',
                    class: 'btn btn-primary btn-serserve-on',
                    click: function () { toggleChecksN(true); }
                }]
            });
        }

        function modalPrivacidadH() {
            $modal.veissModal({
                id: 'pol',
                buttons: [{
                    id: 'polnook',
                    class: 'btn btn-default modal-clear',
                    click: function () { toggleChecksH(false); }
                }, {
                    id: 'polok',
                    class: 'btn btn-primary btn-serserve-on',
                    click: function () { toggleChecksH(true); }
                }]
            });
        }

        /**
         * Cambiar el valor de la propiedad checked.
         */
        function toggleChecks(value) {
            privacidad = value;
            $(politicaInput).prop('checked', value);
        }
        function toggleChecksN(value) {
            privacidadN = value;
            $(politicaNInput).prop('checked', value);
        }
        function toggleChecksH(value) {
            privacidadH = value;
            $(politicaHInput).prop('checked', value);
        }

    });
})(jQuery);


//-----------------------------------------------------------------------
// Detecta Touch device
//-----------------------------------------------------------------------
function is_touch_device() {

    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);

}

//-----------------------------------------------------------------------
// Scroll to element
//-----------------------------------------------------------------------
function scrollTo(id) {
    var offset = $(id).offset();
    var offset_y = offset.top;
    $('body,html').animate({
        scrollTop: offset_y
    }, 1000);
}

//-----------------------------------------------------------------------
// Menu Mobile
//-----------------------------------------------------------------------
function menuMobile(device) {

    var $submenus = $(".header-submenu");

    if (device == "tablet" || device == "mobile" || device == "mobilesmall") {
        $(".menu-item-has-children > a")
            .unbind("click")
            .bind("click", function (e) {
                e.preventDefault();
                
                $submenus.removeClass("header-submenu-active");
                var $submenulocal = $(this)
                    .parent()
                    .find(".header-submenu");
                $submenulocal.addClass("header-submenu-active").slideToggle();
                $submenus.not(".header-submenu-active").slideUp();
            });
    } else {
        $(".menu-item-has-children > a").unbind("click");
    }
}
//-----------------------------------------------------------------------
// Menus Footer
//-----------------------------------------------------------------------
function menuFooter(device) {

    if (device == "tablet" || device == "mobile" || device == "mobilesmall") {

        $('.footer-nav_tit').unbind('click').click(function (e) {

            e.preventDefault();

            $this = $(this);

            $this.toggleClass('footer-nav_tit_down');

            $menu = $this.parent().find('.footer-nav_nav');

            $menu.slideToggle();

        });

    } else {

        $('.footer-nav_tit').unbind('click');

    }

}
//-----------------------------------------------------------------------
// JS media queries
//-----------------------------------------------------------------------
function deviceDetect() {
    this.device = null;

    var cThis = this;

    //Resize eventos
    this.mq_mobile = window.matchMedia(
        "(min-width: 576px) and (max-width: 767px)"
    );
    this.mq_mobile.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = "mobile";
        }
    });

    this.mq_tablet = window.matchMedia(
        "(min-width: 768px) and (max-width: 991px)"
    );
    this.mq_tablet.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = "tablet";
        }
    });

    this.mq_desktop = window.matchMedia(
        "(min-width: 992px) and (max-width: 1199px)"
    );
    this.mq_desktop.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = "desktop";
        }
    });

    this.mq_desktopxl = window.matchMedia("(min-width: 1200px)");
    this.mq_desktopxl.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = "desktoplg";
        }
    });

    //Cuando se carga la página
    if (this.mq_desktopxl.matches) {
        this.device = "desktoplg";
    } else if (this.mq_desktop.matches) {
        this.device = "desktop";
    } else if (this.mq_tablet.matches) {
        this.device = "tablet";
    } else if (this.mq_mobile.matches) {
        this.device = "mobile";
    } else {
        this.device = "mobilesmall";
    }
}
//-----------------------------------------------------------------------
// Defer Images
//-----------------------------------------------------------------------

function load_defer_images() {

    var imgDefer = document.getElementsByClassName('defer-img');
    var imagesLoad = new Array();
    var imagesDom = new Array();
    var imagesSrc = new Array();

    for (var i = 0; i < imgDefer.length; i++) {

        if (imgDefer[i].getAttribute('data-src')) {

            var imageDom = imgDefer[i];
            var imageS = new Image();

            imagesLoad.push(imageS);
            imagesDom.push(imageDom);
            imagesSrc.push(imageDom.getAttribute('data-src'));

        }

    }

    for (var i = 0; i < imagesLoad.length; i++) {

        imagesLoad[i].targetImg = i;

        imagesLoad[i].onload = function (e) {

            var index = this.targetImg;

            $(imagesDom[index]).fadeOut('slow', function () {

                imagesDom[index].src = imagesSrc[index];

                $(this).fadeIn('slow');

            });

        }

        imagesLoad[i].src = imagesSrc[i];

    }

}
/**
 * JavaScripts de VEISS.
 */
$(document).ready(function() {
    //-----------------------------------------------------------------------
    // Hamburger menú mobile
    //-----------------------------------------------------------------------
    var hamburger = $('.hamburger');
    hamburger.menuMobile({
        classOn: 'is-active', //Clase para el boton disparador
        classOnBody: 'body-menu-mobile-on', //Clase para el body
        classOnMenu: 'header-col-menu-mobile-on', //Clase para el menu
        classSubMenus: 'header-submenu', //Clase para lso submenus
        menuMobileWidth: 80 //Tanto por ciento de ancho del menu en mobile
    });

    hamburger.click(function(e) {
        $('.all').toggleClass('all-menu-on');
    });

    //-----------------------------------------------------------------------
    // Scroll top footer
    //-----------------------------------------------------------------------
    $('.footer-scroll-top').scrollToTop();

    //-----------------------------------------------------------------------
    // Animación para botón
    //-----------------------------------------------------------------------
    $('.btn-destacado').click(function (e) {
        $(this).removeClass('bounceInLeft').addClass('bounceIn');
        e.preventDefault();
    });
     //-----------------------------------------------------------------------
    // Hover para dropdown de menú
    //-----------------------------------------------------------------------


    $('.navbar-nav .nav-item').hover(function() {
        var ancho = $(window).width();
        if (ancho > 768){
            $(this).find('.dropdown-menu').stop(true, true).toggleClass('show');
        }
      });
    //-----------------------------------------------------------------------
    // Calculamos la altura del item menú para colocar el submenú desplegable
    //-----------------------------------------------------------------------
    $(window).on('load ', function(){
        var itemMenuHeight = $('.navbar .nav-item').outerHeight();
        var headerHeight = 0;
        var headerHeight = $('header').outerHeight(); 
        var espacioBottom = -8;
        var submenuPosition = itemMenuHeight + espacioBottom;
        $('.dropdown-menu').css('top',submenuPosition);
        var ancho = $(window).width(); 
        if (ancho > 768){
            $('body').css('padding-top',headerHeight);
        }
    });
});
