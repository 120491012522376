;

(function($) {
    $(document).ready(function() {
        //Añadimos una div donde haremos las comprobaciones
        $('body').append('<div class="jquery_media_queries" data-front="jquery-media-queries"></div>');
        //Inicializamos el plugin en la div que acabamos de crear
        $('[data-front="jquery-media-queries"]').jqueryMediaQueries();
    });

    // --------------------------------------------------------------
    // jQuery Plugin
    // Depende de --> modules_less/_part_jquery_media_queries.less
    // Crea un elemento y coje las propiedad que cambiamos con CSS
    // Mayormente para que los eventos de jQuery coincidan con las media queries de Bootstrap
    // --------------------------------------------------------------

    $.jqueryMediaQueries = function(element, options) {
        //Valores por defecto del plugin
        var defaults = {
            cssProperty: 'text-indent', //Propiedad css a checkear
            currentMediaQuerie: 'none', //Media querie actual
        }

        var plugin = this;
        plugin.settings = {}

        var $element = $(element),
            element = element,
            proterty_value = '';

        plugin.init = function() {
            plugin.settings = $.extend({}, defaults, options);
            plugin.property_value = $element.css(plugin.settings.cssProperty);

            $(window).resize(function(){
                plugin.property_value = $element.css(plugin.settings.cssProperty);
            });
        }

        plugin.getMediaQuerie = function() {
            switch(plugin.property_value) {
                case '0px':
                    plugin.settings.currentMediaQuerie = 'xs';
                    break;
                case '768px':
                    plugin.settings.currentMediaQuerie = 'sm';
                    break;
                case '992px':
                    plugin.settings.currentMediaQuerie = 'md';
                    break;
                case '1200px':
                    plugin.settings.currentMediaQuerie = 'lg';
                    break;
            }

            return plugin.settings.currentMediaQuerie;
        }

        plugin.getPropertieValue = function() {
            var property_number = plugin.property_value.replace('px', '');
            property_number = parseInt(property_number);

            return property_number;
        }

        plugin.init();
    }

    $.fn.jqueryMediaQueries = function(options) {
        return this.each(function() {
            if (undefined == $(this).data('jqueryMediaQueries')) {
                var plugin = new $.jqueryMediaQueries(this, options);
                $(this).data('jqueryMediaQueries', plugin);
            }
        });
    }
})(jQuery);
