;
(function($) {

    // --------------------------------------------------------------
    // jQuery Plugin
    // Muestra o oculta el aviso de cookies
    // Depende de https://github.com/js-cookie/js-cookie instalado via Bower
    // --------------------------------------------------------------

    $.cookiesAdvise = function(element, options) {

        //Valores por defecto del plugin

        var defaults = {
            adviseLocation: 'footer', //Donde pone el aviso de cookies
            language: 'es', //Idioma para las cadenas
            locale_url: '/js/locale/'// url para los locales json
        }

        var plugin = this;

        plugin.settings = {}

        var $element = $(element),
            element = element,
            cookie_value,
            advise_html,
            locale_strings;

        plugin.init = function() {

            plugin.settings = $.extend({}, defaults, options);

            //Coge el valor de la cookie
            cookie_value = Cookies.get('cookies-advise');
            //Si no esta definida la crea y renderiza el aviso
            if (cookie_value == undefined || cookie_value != 'ok' )
            {

                Cookies.set('cookies-advise', 'ok', { expires: 365 });
                
                plugin.renderLoadLang();

            }

        }

        plugin.renderAdvise = function () {

            console.log('render');

            advise_html =  '<div data-front="cookie-advise" class="container-fluid cookie-advise">';
            advise_html += '<div class="container">';
            advise_html += '<div class="row cookie-advise-row">';
            advise_html += '<div class="col-12 col-md-8">';
            advise_html += '<p class="cookie-advise-text">'+locale_strings.sentence_1+'</p>';
            advise_html += '<p class="cookie-advise-text">'+locale_strings.sentence_2+' <a href="'+locale_strings.page_url+'">'+locale_strings.sentence_3+'</a>.</p>';
            advise_html += '</div>';
            advise_html += '<div class="col-12 col-md-4 my-auto">';
            advise_html += '<p class="text-center mx-auto"><a data-front="cookie-advise-close" class="btn btn-primary cookie-advise-bt" href="#">'+locale_strings.button_text+'</a></p>';
            advise_html += '</div>';
            advise_html += '<a data-front="cookie-advise-close" href="#" class="cookie-advise-close">'+locale_strings.close_text+'</a>';
            advise_html += '</div>';
            advise_html += '</div>';
            advise_html += '</div>';

            $('#footer').prepend(advise_html);

            console.log('render');

            $('[data-front="cookie-advise-close"]').click(function(e) {
                e.preventDefault();
                $('[data-front="cookie-advise"]').fadeOut('slow', function(){
                    $(this).remove();
                });
            });

        }

        plugin.renderLoadLang = function () {
           
            $.getJSON( plugin.settings.locale_url+'cookie_advise_'+plugin.settings.language+'.json', function( data ) {
                locale_strings = data;
                plugin.renderAdvise();
                
            });

        }

        plugin.init();
    }

    $.fn.cookiesAdvise = function(options) {

        return this.each(function() {
            if (undefined == $(this).data('cookiesAdvise')) {
                var plugin = new $.cookiesAdvise(this, options);
                $(this).data('cookiesAdvise', plugin);
            }
        });

    }

})(jQuery);
